import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const NavBar     =  lazy(() => import("./components/NavBar"));
const Banner     =  lazy(() => import("./components/Banner"));
const Projects   =  lazy(() => import("./components/Projects"));
const Footer     =  lazy(() => import("./components/Footer"));
const Ourwork    =  lazy(() => import("./components/Ourwork"));
const Ourteam    =  lazy(() => import("./components/OurTeam"));
const Sponsors   =  lazy(() => import("./components/Sponsors"));
const ApplyPage   =  lazy(() => import("./components/ApplyPage"));
const ApplicantsPage   =  lazy(() => import("./components/ApplicationPage"));
function App() {
  return (
    <div className="App">
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Suspense fallback={<></>}>
        <NavBar />
          <Router>
        
            <Routes>
              <Route path="/" element={
                <>
                  <Banner />
                  <Ourwork />
                  <Ourteam />
                  <Projects />
                  <Sponsors />
                </>
              }/>
              <Route path="/apply" element={<ApplyPage />} />
              <Route path="/uzinox25" element={<ApplicantsPage />} />
            </Routes>
            <Footer />
          </Router>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
